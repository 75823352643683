<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
    <a href="#" class="navbar-brand">
        <img src="../assets/images/logo.png" height="28" alt="ZIGITAL">
    </a>
    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav">
            <a href="#" class="nav-item nav-link active">INICIO</a>
            <!-- 
            <a href="/quienes-somos" class="nav-item nav-link">QUIENES SOMOS</a>
            <a href="/que-hacemos" class="nav-item nav-link">QUE HACEMOS</a>
            -->
            <a (click)="modalContacto()" class="nav-item nav-link link">CONTACTO</a>
        </div>
        <div class="navbar-nav ml-auto">
            <a href="#" class="nav-item nav-link">Login</a>
        </div>
    </div>
</nav>

<div class="modal fade bd-example-modal-lg" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <app-contacto></app-contacto>
      </div>
    </div>
  </div>
