import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './page/menu/menu.component';
import { CuerpoComponent } from './page/cuerpo/cuerpo.component';
import { FooterComponent } from './page/footer/footer.component';
import { QuienesSomosComponent } from './component/quienes-somos/quienes-somos.component';
import { QueHacemosComponent } from './component/que-hacemos/que-hacemos.component';
import { ContactoComponent } from './component/contacto/contacto.component';

//import { ChatService } from './service/chat.service'
import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from  '@angular/forms';
import { NosanitizePipe } from './pipe/nosanitize.pipe';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    CuerpoComponent,
    FooterComponent,
    QuienesSomosComponent,
    QueHacemosComponent,
    ContactoComponent,
    NosanitizePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
