<section>
    <div class="container">
        <br />
      <div class="row">

        <div class="col-md-6">
          
            <div class="alert alert-success" role="alert">
                <h4 class="alert-heading">QUE HACEMOS</h4>
                <br />
                <h5 class="alert-heading">Desarrollo Web con chatbot</h5>
                <br />
                <p>
                    El Desarrollo Web con Chatbots es parte de el internet 4.0, hace mas cercana la pagina a las personas, permine
                    entregar mejores servicio de venta o post venta, permite tener un contacto 24/7 con los clientes. Hoy
                    en día existe chatbot muy amigables con el cliente entregando una experiencia usuario diferenciadora
                    y 
                </p>

                <br />
                <h5 class="alert-heading">Transformación Dígital</h5>
                <br />
                <p>
                    Las empresas deben estar en una constante adaptación a las nuevas técnologias, lo que es fundamental para 
                    llegar al usuario, si algo nos esta enseñando el Covid-19 es que estar actualizado con nuestros sistemas nos 
                    ayuda a estar en una mejor situación frente a cambios bruscos del mercado. Hoy nuestra pagina web es la cara
                    hacia el cliente, es nuestro mejor vendedor de productos y servicios, es la intefaz que nos relaciona con nuestros 
                    clientes, nos comunica con nuestros proveedores y mas. 
                    <br />
                    Por eso es que te ayudamos en tu Transformacion Digital realizando un diagnóstico, entregando propuestas segun tu
                    necesidad, desarrollando los sistemas que mas necesitas y acompañando en desarrollar talentos y acompañar en 
                    el manejo de la gestión del cambio.
                </p>

                <br />
                <h5 class="alert-heading">G Suite</h5>
                <br />
                <p>
                    Somos Partner Google por lo que te asesoramos para el manejos de las herramientas de G Suite, realizando capacitaciones
                    para que puedas sacar el mejor provecho a las herramientas que te entrega Google 

                </p>
                <p>
                    Hoy aceptamos desafios relacionados con Data Science, creación de Data Lake, implementación de Dashboard ya sea en 
                    Power BI o creando soluciones Web, limpieza  e ingesta de datos y todo lo referente a ayudar a nuestros clientes
                    en tener una correcta administración de los datos para la toma de desiciones. 
                </p>
            </div>
        </div>
        
      </div>
    </div>
    <br>
  </section>
