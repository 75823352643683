<!-- Site footer -->
<footer class="site-footer">
    <div class="container">
      <br>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h4>ZIGITAL</h4>
          <p class="text-justify"> 
            <br />
            Zigital | Tecnología | Ingeniería |  Machine Learning |  Cloud
            <br />
            &nbsp; <a href="mailto:contacto@zigital.cl">contacto@zigital.cl</a>
            <br />
            <!-- 
            &nbsp;&nbsp; <i class="fa fa-dollar"></i> &nbsp; <a href="/trekking">Trekking</a>
            <br />
            &nbsp;&nbsp; <i class="fa fa-blog"></i> &nbsp; <a href="/termas">Termas</a>  
            -->
        </p>
        </div>

        <div class="col-xs-6 col-md-3">
          <br><br>
          <ul class="footer-links">
            <li><a href="/">INICIO</a></li>
            <!--
            <li><a href="/quienes-somos">QUIENES SOMOS</a></li>
            <li><a href="/que-hacemos">QUE HACEMOS</a></li>
            -->
            <li><a class="link" (click)="modalContacto()" >CONTACTO</a></li>
          </ul>
        </div>

        <div class="col-xs-6 col-md-3">
          <h6>Casos de Éxito</h6>
          <ul class="footer-links">
            <li><a href="http://www.chilenture.cl" target="_blank">Chilenture.cl</a></li>
            <li><a href="http://www.openu.cl" target="_blank">OpenU.cl</a></li>
          </ul>
        </div>
      </div>
      <br>
      <hr>
    </div>

    <br>

    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-6 col-xs-12">
          <p class="copyright-text">Copyright &copy; 2020 todos los derechos pertenecen a  
       <a href="http://zigital.cl" target="_blank">Zigital.cl</a>
          </p>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <ul class="social-icons">
            <li><a class="facebook" href="https://www.facebook.com/chilenture" target="_blank"><i class="fa fa-facebook"></i></a></li>
            <li><a class="instagram" href="http://instagram.com/chilenture" target="_blank"><i class="fa fa-instagram"></i></a></li>
            <!--<li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="dribbble" href="#"><i class="fa fa-dribbble"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>   -->
          </ul>
        </div>
      </div>
    </div>
</footer>

