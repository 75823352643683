<section>
    <div class="container">
        <button type="button" class="close float-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <br />
        <h3 class="alert-heading">Formulario de Contacto</h3>
        <br />
      <div class="row">

        <div class="col-md-12">
          
            <form [formGroup]="contactoForm" (ngSubmit)="submitFormulario()" novalidate>

                <div class="form-group">
                    <label for="nombre">Nombre</label>
                    <input type="text" formControlName="nombre" class="form-control" id="nombre" aria-describedby="emailHelp" placeholder="Ingrese Nombre" required>
                </div>

                <div class="form-group">
                  <label for="email1">Email</label>
                  <input type="email" formControlName="email" class="form-control" id="email1" aria-describedby="emailHelp" placeholder="Ingrese Email">
                </div>

                <div class="form-group">
                  <label for="telefono1">Telefono</label>
                  <input type="telefono" formControlName="telefono" class="form-control" id="telefono1" aria-describedby="emailHelp" placeholder="Ingrese Telefono">
                </div>

            <div class="form-group">
                    <label for="textarea1">Motivo</label>
                    <textarea class="form-control" id="textarea1" rows="3"></textarea>
                    <small id="emailHelp" class="form-text text-muted">Detalle el motivo del mensaje.</small>
                  </div>

                  <button type="submit" class="btn btn-primary">Enviar</button>
            </form>

        </div>
        
      </div>
    </div>
    <br>
  </section>