
  <div class="jumbotron jumbotron-fluid fondo1">
    <div class="container">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <!-- slice 1 -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="container capa-titulo">
                            <span class="titulo">Diseño Web con Chatbot</span>
                            <br /><br /><br />
                            <p class="texto-titulo text-justify">
                                Nos especializamos en diseño de páginas web con chatbot para mejorar la experiencia usuario 
                                de tus clientes o futuros clientes.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="container capa-titulo2">
                            <img class="fade-in" src="../../../assets/images/chatbot_160.png" width="166" height="177" >
                          </div>        
                    </div>      
                </div>

              </div>
              <div class="carousel-item">
                <!-- slice 2 -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="container capa-titulo2">
                            <img src="../../../assets/images/home/data-science-cloud.png" width="360" height="196" >
                          </div>        
                    </div>
                    <div class="col-md-6">
                        <div class="container capa-titulo">
                            <span class="titulo">Data Science as Service</span>
                            <br /><br /><br />
                            <p class="texto-titulo text-justify">
                                Data Science es una de las diciplinas mas importantes para el manejo de Datos y
                                la simplificamos entregando como un servicio.
                            </p>
                        </div>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <!-- slice 3 -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="container capa-titulo">
                            <span class="titulo">Transformación Digital</span>
                            <br /><br /><br />
                            <p class="texto-titulo text-justify">
                                Te acompañamos en los cambios que necesita tu empresa para enfrentar los desafío tecnológicos de 
                                la actualidad.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="container capa-titulo2">
                            <img src="../../../assets/images/home/trans_dig.png" width="275" height="241" >
                          </div>        
                    </div>      
                </div>
              </div>
              <div class="carousel-item">
                <!-- slice 4 -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="container capa-titulo2">
                            <img src="../../../assets/icon/online-learning.png" width="212" height="212" >
                          </div>        
                    </div> 
                    <div class="col-md-6">
                        <div class="container capa-titulo">
                            <span class="titulo">Online Learning</span>
                            <br /><br /><br />
                            <p class="texto-titulo text-justify">
                                Contamos con una plataforma de aprendizaje online que puedes utilizar para 
                                capacitaciones o en cualquier institución de enseñanza.
                            </p>
                        </div>
                    </div>     
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        <!--
            <span class="display-4 pre-titulo">ES HORA DE </span>
        -->
    </div>
  </div>

  <section>
    <div class="container">
        <br><br /><br />
        <h1>Como Trabajamos</h1>
        <br /><br /><br /><br />
      <div class="row">
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-3">
                    <img class="img-fluid float-left" src="../../../assets/icon/chat-bot.png" width="45" > 
                </div>
                <div class="col-md-9 text-justify">
                    <h4> Chatbot </h4>
                    Los Chatbot llegaron para quedarse, se pueden utilizar para entregar infomación de productos, post-venta, 
                    posicionamiento de marca.
                </div>
            </div>
            
            <br>
            <div class="row">
                <div class="col-md-3">
                    <img class="img-fluid float-left" src="../../../assets/icon/ai.png" width="45" > 
                </div>
                <div class="col-md-9 text-justify">
                    <h4> Machine Learning </h4>
                    Trabajamos con Machine Learning para realizar modelos predictivos con los datos obtenidos de distintas fuentes.
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <img class="img-fluid float-left" src="../../../assets/icon/ok-marca.png" width="45" > 
                </div>
                <div class="col-md-9 text-justify">
                    <h4> MVP </h4>
                    Minimum Viable Product es la entrega de sistemas en forma modular y operativa para que puedan ir viendo 
                    el avance en la solución final.
                </div>
            </div>
        </div>
   
        <div class="col-md-4">          
            <img class="img-fluid" src="../../../assets/images/home/juntos.png" >
        </div>
        
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-3">
                    <img class="img-fluid float-left" src="../../../assets/icon/red.png" width="45" > 
                </div>
                <div class="col-md-9 text-justify">
                    <h4> Cloud </h4>
                    Trabajar en la nube potencia la velocidad y calidad de los productos y nos apoyamos en Google Cloud Platform
                    para relizar nuestras soluciones.
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <img class="img-fluid float-left" src="../../../assets/icon/united.png" width="45" > 
                </div>
                <div class="col-md-9 text-justify">
                    <h4> Team </h4>
                    Nos enfocamos en potenciar el trabajo el equipo, y entregar independencia y capacitación a los equipos.
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <img class="img-fluid float-left" src="../../../assets/icon/agil.png" width="45" > 
                </div>
                <div class="col-md-9 text-justify">
                    <h4> Agile </h4>
                    Trabajamos con metodologías Ágiles para optimizar el tiempo de desarrollo, 
                    entregar de productos funcionales y en el tiempo óptimo.
                </div>
            </div>
        </div>
        

      </div>
    </div>
    <br><br><br><br>
  </section>

  <div class="jumbotron jumbotron-fluid fondo-cotizacion">
    <div class="container banner-cotizacion">
        <h2>¿En qué te podemos Ayudar?</h2>
        <a class="link" (click)="modalContacto()">Comunícate con nosotros</a>
    </div>
  </div>

  <section>
    <div class="container">
        <br><br />
        <h1>E-Learning as Service</h1>
        <br /><br /><br />
      <div class="row">
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-3">
                    <img class="img-fluid float-left" src="../../../assets/icon/online-learning.png" width="45" > 
                </div>
                <div class="col-md-9 text-justify panel-item">
                    El aprendizaje en línea ha tenido un crecimiento explosivo en los últimos años y aun más debido a la pandemia.
                    Para poder educar a distancia se hace necesario tener una plataforma robusta, confiable y amigable.
                    <br><br>
                    Hoy tenemos una solución en plataforma Online, en <a href="http://www.openu.cl" target="_blank">  Openu.cl </a>  entregamos
                    contenidos tecnológicos de punta como lo es el Desarrollo Web Full Stack, Data Science y cursos 
                    de Python, Angular y Node js.
                </div>
            </div>
        </div>
   
        <div class="col-md-2">          
            <div >
                <p>
                    &nbsp;
                </p>
            </div>
        </div>
        
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-3">
                    <img class="img-fluid float-left" src="../../../assets/icon/digital-learning.png" width="45" > 
                </div>
                <div class="col-md-9 text-justify panel-item">
                    Tenemos a tu disposición una plataforma E-Learning totalmente administrada, mantenida
                    y en un entorno Cloud para satisfacer los requerimientos que necesites, obteniendo un servicio de E-Learning 
                    a tu dsposición ya sea para capacitaciones internas de tu organización o también podemos disponibilizar la misma 
                    plataforma en forma exclusiva según tus necesidades.
                    <br><br>
                    Si tienen cualquier consulta o quieres una prueba solicítala <a class="link" (click)="modalContacto()">aquí</a>.

                </div>
            </div>
        </div>
        
      </div>
    </div>
    <br><br><br><br>
  </section>

  <div class="jumbotron jumbotron-fluid fondo-mac">
    <div class="container">
        <br><br />
        <h1>Nuestros Servicios</h1>
        <br /><br /><br /><br />
      <!-- Fila 1 -->
      <div class="row">
        <div class="col-md-4 col-shadow">
            <div class="alert alert-secondary servicios" role="alert">
                
                <img class="img-fluid mx-auto d-block img-servicios" src="../../../assets/icon/chat-bot.png" width="90" >
                <h4 class="alert-heading text-center">Desarrollo web con chatbot</h4>
                <hr>
                <p class="text-justify">
                    Desarrollamos Web con Chatbot para web publicas o internas,
                    implementando asistencia 24/7 para tus clientes, usuarios, colaboradores,
                    futuros clientes o incluso con los proveedores.
                </p>
                <br /><br />
            </div>
        </div>
        
        <div class="col-md-4  col-shadow">
            <div class="alert alert-secondary servicios" role="alert">

                <img class="img-fluid mx-auto d-block img-servicios" src="../../../assets/icon/tdigital.png" width="90" >
                <h4 class="alert-heading text-center">Transformación Digital</h4>
                <hr>
                <p class="text-justify"> 
                    Te acompañamos en los cambios tecnológicos que necesitas, contamos con las
                    mejores herramientas y equipo para realizar el desafío que necesitas concretar. 
                </p>
                <br /><br />
            </div>
        </div>

        <div class="col-md-4  col-shadow">
            <div class="alert alert-secondary servicios" role="alert">

                <img class="img-fluid mx-auto d-block img-servicios" src="../../../assets/icon/digital.png" width="90" >
                <h4 class="alert-heading text-center">Desarrollo de Software</h4>
                <hr>
                <p class="text-justify">
                    Desarrollamos los sistemas que requieras ya sea una Web con Chatbot responsivas, Api Rest, 
                    Dashboard; aceptamos los desafíos que tengan tus proyectos, <a class="link" (click)="modalContacto()">contáctanos</a>.
                </p>
                <br /><br />
            </div>
        </div>

    </div>
    <br><br>
    <!-- Fila 2 -->
    <div class="row">
        <div class="col-md-4 col-shadow">
            <div class="alert alert-secondary servicios" role="alert">

                <img class="img-fluid mx-auto d-block img-servicios" src="../../../assets/icon/company.png" width="90" >
                <h4 class="alert-heading text-center">Google Workspace </h4>
                <hr>
                <p class="text-justify">
                    Te asesoramos en la utilización e integración de los productos Google Workspace para el buen funcionamiento en tu
                    organización. Estas herramientas te ayudaran a la mejor comunicación de tus colaboradores.
                </p>
                <br /><br />
            </div>
        </div>
        
        <div class="col-md-4 col-shadow">
            <div class="alert alert-secondary servicios" role="alert">

                <img class="img-fluid mx-auto d-block img-servicios" src="../../../assets/icon/cloud-computing.png" width="90" >
                <h4 class="alert-heading text-center">Google Cloud Platform</h4>
                <hr>
                <p class="text-justify">
                    Google Cloud Platform o GCP es un conjunto de servicios disponibles como Base de datos SQL y NoSQL, 
                    Máquinas Virtuales, Pipeline de Datos, servicios de Machine Learning, Data Lake o Data Warehouse entre otros. 
                </p>
                <br /><br />
            </div>
        </div>

        <div class="col-md-4 col-shadow">
            <div class="alert alert-secondary servicios" role="alert">

                <img class="img-fluid mx-auto d-block img-servicios" src="../../../assets/icon/data-analytics.png" width="90" >
                <h4 class="alert-heading text-center">Data Science as Services</h4>
                <hr>
                <p class="text-justify">
                    En el servicio de Data Science realizamos exploración de datos, mejora en la calidad de datos para la toma de 
                    decisiones inteligente con los reportes o visualización de datos por medio de dashboard. Desde los datos
                    obtenemos conocimiento.
                </p>
                <br /><br />
            </div>
        </div>

    </div>
        
    </div>
  </div>

  <div class="jumbotron jumbotron-fluid fondo-partner">
    <div class="container banner-partner">
        <br />
        <h1>Nuestros Partners</h1>
        <br />
        <br />
        <div class="row">
            <div class="col-md-6 parner1">          
                <img class="img-fluid img-gcp" src="../../../assets/partner/gcp.png" >
            </div>

            <div class="col-md-6 parner1">          
                <img class="img-fluid img-aws" src="../../../assets/partner/aws_logo.png" >
            </div>
        </div>
        
    </div>
  </div>

<!-- chat -->
<div class="logoChatbot" (click)="fshowBot()">
    <img *ngIf="!showBot" src="../assets/images/chatbot_100.png" alt="Zigital.cl">
</div>

  <div class="inbox_msg" *ngIf="showBot">
    <div class="inbox_people">
      <div class="mesgs">
        <button class="msg_min_btn" type="button" (click)="showBot = !showBot"><i class="fa fa-window-minimize" aria-hidden="true"></i></button>
        
        <div id="history" class="msg_history" [innerHtml]="contenido | safeHtml"></div>
  
        <div class="type_msg">
          <div *ngIf="landing" class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
  
          <div *ngIf="!landing" class="input_msg_write">
            <form (submit)="getPregunta()" id="form">
              <input id="textoBot" type="text" class="write_msg" placeholder="Escribir pregunta" [(ngModel)]="inputBot" name="textoBot" />
            </form>
            <button class="msg_send_btn" type="button" (click)="getPregunta()"><i class="fa fa-paper-plane-o" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>