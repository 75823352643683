import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Mensaje } from '../model/mensaje';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  URL : string = 'https://us-central1-zigital-cl.cloudfunctions.net/app/api/bot';

  constructor(protected http: HttpClient) { }

  public getRespuesta( intento : string, sesion : string ): Observable<Mensaje>{
    console.log('getRespuesta:::' + intento);
    return this.http.post<Mensaje>(this.URL, { message: intento, sesion: sesion});

  }
}
