<section>
    <div class="container">
        <br />
      <div class="row">

        <div class="col-md-6">
          
            <div class="alert alert-success" role="alert">
                <h4 class="alert-heading">QUIENES SOMOS</h4>
                <p>Somos una empresa de desarrollo de software a medida, nos encantan los desafíos tecnólogicos,
                    para lo cual nos enficamos en tener una cultura de trabajo amigable tanto para nuestros trabajadores como
                    para el cliente, enfocados en la creatividad y empatía con el cliente.
                </p>

                <p>
                    Zigital se fundó en el año 2019 dedicados principalmente al desarrollo web con chatbot, además de la creación 
                    de productos propios como lo son <a> OpenU.cl </a> y <a> Chilenture.cl </a>. Además de explorar en el análisis
                    de Big Data y la utilización de los servicios de Google Cloud Platform.
                </p>

                <p>
                    Trabajamos con los frameworks principales en el mercado como lo es el stack Java, angular2+, python, GCP con servicios
                    como DialogFlow, Storach, Cloud Sql, Dataflow, Firestore y los servicios de Firebase.
                </p>
                <p>
                    Hoy aceptamos desafios relacionados con Data Science, creación de Data Lake, implementación de Dashboard ya sea en 
                    Power BI o creando soluciones Web, limpieza  e ingesta de datos y todo lo referente a ayudar a nuestros clientes
                    en tener una correcta administración de los datos para la toma de desiciones. 
                </p>

                <p>
                    Nuestro objetivo es simplificar la administracion de los datos, automatizar procesos, resolver problemas complejos, 
                    empoderarse, solucionar sus dolores y mejorar lo que hacen. Por eso nuestra politica es fomentar la relacion a 
                    largo plazo en función de proyectos exitos acompañandolos en todas la etapas de la solución entregda.
                </p>

                <p>
                    En Zigital nos enfocamos en generar politicas de coperación mutua, potenciar los talentos y creatividad y cultivar la autonomía.
                    Los invitamos a crecer, fortalecer y afrontar los desafíos tecnológicos que nos impone la actualidad.
                </p>
            </div>
        </div>
        
      </div>
    </div>
    <br>
  </section>
