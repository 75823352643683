import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({
  name: 'safeHtml'
})
export class NosanitizePipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {

  }
  transform(html: string): SafeHtml {
    //const sanitizedContent = DOMPurify.sanitize(value);
     return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
