import { Component, OnInit } from '@angular/core';

import { ChatService } from '../../service/chat.service';
import { Mensaje } from '../../model/mensaje';

import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

declare var $: any;

@Component({
  selector: 'app-cuerpo',
  templateUrl: './cuerpo.component.html',
  styleUrls: ['./cuerpo.component.css']
})
export class CuerpoComponent implements OnInit {
  
  nuevoMensaje : Mensaje = new Mensaje('');
  showBot : boolean = true;
  respuesta : string = '';
  contenido : string = '';
  landing : boolean = true;
  inputBot: string = '';
  countDiv :number = 0;
  sesion : string = '';

  constructor( protected botServices : ChatService ) { 

  }

  ngOnInit(): void {
    
    this.showBot = false;
    this.respuesta = 'Bienvenido a Zigital';
    this.countDiv = 0;
    this.contenido = this.getRespuesta( this.respuesta );
    this.landing = false;
    
    /* $('button').click(function(){
      alert('Wass up!');
       }); */
  }

  modalContacto(){
    $('#myModal').modal('show');
  }

  messageToBot( mensaje: string){
    //let data = this.backService.chatBot( 'Hola' ).subscribe( data => this.mensaje=data );
    //console.log('messageToBot::' + mensaje);
    this.botServices.getRespuesta( mensaje, this.getSesion() ).subscribe(
          (res : any) => {
            //console.log( 'res:' + res.respuesta);
            let respuesta = this.getRespuesta( res.respuesta );
            this.contenido = this.contenido + respuesta;
            this.landing = false;
            this.nuevoMensaje = res;
            let sDiv = '#resp' + this.countDiv;
            let valScroll : string = "" + (200 + (100*this.countDiv));
            
            $( "#history" ).animate(  { scrollTop: valScroll}, 999);

            $(document).ready( function() {
                function hi(){
                  //$("input:text").focus();
                  $( "#form #textoBot" ).focus();
                }
                setInterval(hi, 1000);
                hi();
            });

            //
            //$("input").focus();
          }
    );
    //console.log('setMensaje:' + data );
  }
 
  getSesion() : string {
    if ( this.sesion=='' ){
      this.sesion = uuidv4();
    }
    return this.sesion;
  }

  getRespuesta( value: string ):string{
    this.countDiv = this.countDiv + 1;
    let salida= `<div id="resp${this.countDiv}" style="overflow:auto;">
                  <div style=" display: inline-block;width: 6%;"> <img src="../assets/images/chatbot_40.png" alt="sunil"> </div>
                  <div style="display: inline-block;padding: 0 0 0 14px;vertical-align: top;width: 92%;">
                    <div style="width: 70%;">
                      <p style="background: #4B8BBE none repeat scroll 0 0;
                                border-radius: 3px;
                                color: #fff;
                                font-size: 14px;
                                margin: 0;
                                padding: 5px 10px 5px 12px;
                                width: 100%;">
                        ${value}
                      </p>
                    </div>
                  </div>
                </div>`;
    return salida;
  }

  getPregunta( ){
    if (this.inputBot==undefined) return;
    this.messageToBot(this.inputBot);
    //console.log('getPregunta:::' + this.inputBot);
    let pregunta = `<div style="overflow:auto; margin:26px 10px 26px 0;">
            <div style="float: right;
                        margin-right: 20px;
                        width: 70%;">
              <p style="background: #ebebeb none repeat scroll 0 0;
                        border-radius: 3px;
                        font-size: 14px;
                        margin: 0; color:#646464;
                        padding: 5px 10px 5px 12px;
                        width:100%;">
                  ${this.inputBot}
               </p>
            </div>
          </div>`;
    this.contenido = this.contenido + pregunta;
    this.inputBot = '';
    this.landing = true;
  }

  fshowBot(){
    this.showBot = !this.showBot;
    $(document).ready( function() {
      function hi(){
        //$("input:text").focus();
        $( "#form #textoBot" ).focus();
      }
      setInterval(hi, 100);
      hi();
    });
  } 
  
}
