import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CuerpoComponent } from './page/cuerpo/cuerpo.component';
import { QueHacemosComponent } from './component/que-hacemos/que-hacemos.component';
import { QuienesSomosComponent } from './component/quienes-somos/quienes-somos.component';

const routes: Routes = [
  { path : '', component: CuerpoComponent, pathMatch: 'full' }, 
  { path : 'inicio', component: CuerpoComponent }, 
  /*
  { path : 'quienes-somos', component: QuienesSomosComponent }, 
  { path : 'que-hacemos', component: QueHacemosComponent }, 
  */
  { path : '**', component: CuerpoComponent } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
